/**
 * @generated SignedSource<<0c549a0e2080bf668aac6a967b132d98>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StorefrontAboutSection_seller$data = {
  readonly sellerPreferences: {
    readonly scrollLogoLg: string | null;
  } | null;
  readonly sellerProfile: {
    readonly company: string | null;
  } | null;
  readonly storefrontProfile: {
    readonly aboutUs: string | null;
    readonly establishmentYear: string | null;
    readonly logo: {
      readonly path: string | null;
    } | null;
  } | null;
  readonly tradeAssociations: ReadonlyArray<{
    readonly displayName: string | null;
  } | null> | null;
  readonly vatInfo?: ReadonlyArray<{
    readonly vatId: string | null;
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontContactModal_seller">;
  readonly " $fragmentType": "StorefrontAboutSection_seller";
};
export type StorefrontAboutSection_seller$key = {
  readonly " $data"?: StorefrontAboutSection_seller$data;
  readonly " $fragmentSpreads": FragmentRefs<"StorefrontAboutSection_seller">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "showVatInfo"
    },
    {
      "kind": "RootArgument",
      "name": "storefrontId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "StorefrontAboutSection_seller",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StorefrontContactModal_seller"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "storefrontId",
          "variableName": "storefrontId"
        }
      ],
      "concreteType": "StorefrontProfile",
      "kind": "LinkedField",
      "name": "storefrontProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "aboutUs",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Photo",
          "kind": "LinkedField",
          "name": "logo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "path",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "establishmentYear",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SellerProfile",
      "kind": "LinkedField",
      "name": "sellerProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "company",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SellerPreferences",
      "kind": "LinkedField",
      "name": "sellerPreferences",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "scrollLogoLg",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TradeAssociation",
      "kind": "LinkedField",
      "name": "tradeAssociations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "condition": "showVatInfo",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VatInfoType",
          "kind": "LinkedField",
          "name": "vatInfo",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "vatId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Seller",
  "abstractKey": null
};

(node as any).hash = "00ce35eeca1fd16a198cf311ac673593";

export default node;
