/**
 * @generated SignedSource<<06bb54d92e8ab8d1b855581292e472fb>>
 * @relayHash 42c560fc96cfff1bd1d0f6f27b505a07
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-misc/494.0.0-2025-01-15T20:29:17.394Z/FavoritesItemProviderQuery

import { ConcreteRequest, Query } from 'relay-runtime';
export type FavoritesItemProviderQuery$variables = {
  userId: string;
};
export type FavoritesItemProviderQuery$data = {
  readonly viewer: {
    readonly user: {
      readonly canShowFolder: boolean | null;
    } | null;
  };
};
export type FavoritesItemProviderQuery = {
  response: FavoritesItemProviderQuery$data;
  variables: FavoritesItemProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canShowFolder",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FavoritesItemProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FavoritesItemProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-misc/494.0.0-2025-01-15T20:29:17.394Z/FavoritesItemProviderQuery",
    "metadata": {},
    "name": "FavoritesItemProviderQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "7cf5383b5c927e7bfa59d1e3b9cc21f5";

export default node;
